import { template as template_7a03446f70464104a248a6d3e15eabc0 } from "@ember/template-compiler";
const SidebarSectionMessage = template_7a03446f70464104a248a6d3e15eabc0(`
  <div class="sidebar-section-message-wrapper sidebar-row">
    <div class="sidebar-section-message">
      {{yield}}
    </div>
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default SidebarSectionMessage;
