import { template as template_3491f59ea1de42a7be63fadb54bbea2d } from "@ember/template-compiler";
const FKText = template_3491f59ea1de42a7be63fadb54bbea2d(`
  <p class="form-kit-text" ...attributes>
    {{yield}}
  </p>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKText;
