import { template as template_cd167540bb5c45a59ed1c9814f55946e } from "@ember/template-compiler";
const WelcomeHeader = template_cd167540bb5c45a59ed1c9814f55946e(`
  <div class="login-welcome-header" ...attributes>
    <h1 class="login-title">{{@header}}</h1>
    {{yield}}
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default WelcomeHeader;
