import { template as template_31dc9355df484839b578faf22562a87d } from "@ember/template-compiler";
const FKLabel = template_31dc9355df484839b578faf22562a87d(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
