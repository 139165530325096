import { template as template_cde6e8d671d64467b49d89b0b1b504e2 } from "@ember/template-compiler";
import DButton from "discourse/components/d-button";
const BackButton = template_cde6e8d671d64467b49d89b0b1b504e2(`
  <DButton
    @action={{@onGoBack}}
    @label="topic.timeline.back"
    @title="topic.timeline.back_description"
    class="btn-primary btn-small back-button"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default BackButton;
